<template>
	<v-card
		class="mx-auto"
		elevation="0"
	>
    <v-card-title class="card-header text-left fixed-card-title2">
			<v-row>
				<v-col cols="10">

				<v-toolbar-title >{{$t('add-staff')}}</v-toolbar-title>
				</v-col>
        <v-col cols="2" class="text-right mt-n1">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
			</v-row>
		</v-card-title>
		<!-- Role -->
		<v-card-text class="pa-7">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
			>
        <v-row>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="firstname"
                :rules="[ v => !!v || 'Item is required']"
                outlined
                dense
                hide-details="auto"
                :label="$t('name')"
                required
                :key="componentKey"
            ></v-text-field>

          </v-col>

          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="lastname"
                :rules="[ v => !!v || 'Item is required']"
                outlined
                dense
                hide-details="auto"
                :label="$t('surname')"
                required
                :key="componentKey"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="email"
                outlined
                dense
                hide-details="auto"
                :label="$t('email')"
                :rules="[
                  v => !!v || $t(''),
                  v => /.+@.+\..+/.test(v) || $t('validEmail')
              ]"
                required
                :key="componentKey"
            >

            </v-text-field>
          </v-col>
          <v-col
              class="d-flex pa-1"
              cols="12"
              sm="6"
          >
            <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model="phone"
                :label="$t('phone')"
                required
                :key="componentKey"
            ></v-text-field>
          </v-col>
        </v-row>
				<v-row>
					<v-col
						class="d-flex pa-1"
						cols="12"
						sm="12"
					>
						<v-select
							class="width-text-field2"
							hide-details
							dense
							v-model="selectedHospitalId"
							:items="filteredHospitals"
							item-value="id"
							item-text="hospitalName"
							:label="$t('hospital')"
							outlined
							required
              :key="componentKey"
              @change="setSelectedHospital"
            >
						</v-select>
					</v-col>
				</v-row>
				<v-row
					class="d-flex pa-1"
					cols="12"
				>
						<v-select
							:label="$t('role')"
							outlined
							required
              hide-details
							dense
							:item-text="this.$i18n.locale === 'en' ? 'roleNameForDisplay' : 'roleNameForDisplayESP'"
							item-value="id"
							v-model="role"
							:items="roles"
						></v-select>
				</v-row>
        <v-row v-if="!addressIsValid">
        <v-col
            class="d-flex pa-1"
            cols="12"
        >
          <v-combobox
              :label="$t('time-zone')"
              dense
              hide-details="auto"
              outlined
              :rules="[v => !!v || $t('timeZoneSelect')]"
              v-model="timezone"
              item-text="timeZoneId"
              item-value="timeZoneId"
              :items="getTimeZone"
              required
              :key="componentKey"
          ></v-combobox>

        </v-col>
        </v-row>
				<v-row>
					<v-divider class="mx-1 my-3"></v-divider>
				</v-row>
				<!-- Speialization -->
				<v-row v-if="role === 3" class="d-flex pa-1">
					<v-select
							outlined
							dense
							hide-details="auto"
							:label="$t('specializations')"
							multiple
              class="capitalize-text"
							v-model="specialization"
							:item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
							item-value="lowValue"
							:items="getSpecializationsForForm"
					></v-select>
				</v-row>
				<!-- Divider -->
				<v-row  v-if="role === 3">
					<v-divider class="mx-1 my-3"></v-divider>
				</v-row>
			</v-form>
		</v-card-text>
    <v-card-actions class="fixed-card-actions">
			<v-row class="mb-0">
			<v-col cols="6">
				<v-btn
				@click="closeDrawer"
					elevation="0"
					class="capitalize-text cancel-button-style"
				>
				{{$t('cancel')}}
				</v-btn>
			</v-col>
			<v-col cols="6">
				<v-btn
					:disabled="!valid ||  role === null || firstname === '' || lastname === '' || timezone === null || email === '' || phone === ''  || selectedHospitalId === null "
					class="save-button-style"
					@click="saveStaff"
				>
				{{$t('save')}}
				</v-btn>
			</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	name: 'DashboardCoreAppBar',
	components: {},
	props: [],
	data: () => ({
		valid: true,
		timezone: null,
		imageSrc: null,
		specialization: null,
		role: '',
		firstname: '',
		lastname: '',
		phone: '+1',
		phoneRules: [],
    componentKey: 0,
		email: '',
		selectedHospitalId: null,
    selectedHospital: null,
    addressIsValid: true,
  }),
	async mounted () {
		await this.loadData();
	},

	computed: {
		...mapState({
			hospitals: (state) => state.hospitals.hospitals,
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			roles: 'roles/getRolesForForm',
			getSpecializationsForForm: 'verifiers/getSpecializationsForForm',
			getTimeZone: 'verifiers/getTimeZone',
			// getHospital: 'authentication/getHospital',
			roleName: 'authentication/getRole',
		}),
		getHospital: {
			get () {
				// return this.userData.hospitalAssignedData;
				const selectedHospital = this.selectedHospitalId == null ? null : this.hospitals.find(h => h.id === this.selectedHospitalId);
				return selectedHospital;
			},
		},
		filteredHospitals () {
			const calculateFileteredHospital = this.hospitals;
			return calculateFileteredHospital;
		},
	},
	methods: {
    setSelectedHospital (val) {
      this.timezone = null;
      this.selectedHospital = this.hospitals.find(hospital => hospital.id === val);

      if (this.selectedHospital.addressLatitude !== 0.01 && this.selectedHospital.addressLongitude !== 0.01 && this.selectedHospital.addressLongitude !== null && this.selectedHospital.addressLatitude !== null) {
        this.addressIsValid = true;

        const latLng = {
          lat: this.selectedHospital.addressLatitude,
          lng: this.selectedHospital.addressLongitude,
        };

        this.$store.dispatch(
            'googleAPI/getTimeZone',
            latLng,
        ).then(response => {
          this.timezone = response.zoneName;
        });
      } else {
        // TODO There is bad address show time zoene lookup
        this.addressIsValid = false;
        this.selectedHospital.addressLatitude = 0.01;
        this.selectedHospital.addressLongitude = 0.01;
      }
    },
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.firstname = '';
      this.email = '';
      this.phone = '+1';
      this.timezone = '';
      this.lastname = '';
      this.role = '';
      this.selectedHospitalId = '';
      // Change the key to trigger component reset
      this.componentKey += 1;
    },
		async loadData () {
			await this.loadTimeZone();
			await this.getAllHospitals();
		},
		async loadTimeZone () {
			this.$store.dispatch('verifiers/getTimeZones').then(() => {
			});
		},
		async getAllHospitals () {
			// we call this API endpoint only if the store values are null
			if (this.hospitals == null) {
				await this.$store.dispatch('hospitals/getHospitals').then(() => {
					// this.loading = false;
				});
			}
		},
		// getStaff () {
		// 	this.$store
		// 		.dispatch('hospitals/getRelatedMedicalStaff', this.getHospital.id).then(() => {
		// 			this.closeDrawer();
		// 		});
		// },
		async getStaff () {
			// this.loading = true;
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const organizationId = this.userData.organizationId;
				await this.$store
					.dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
					.then(() => {
						// this.loading = false;
					});
			} else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				const hospitalId = this.userData.hospitalAssignedData.id;
				await this.$store
					.dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
					.then(() => {
						// this.loading = false;
					});
			}
		},
		async saveStaff () {
			if (this.$refs.form.validate()) {
				const body = {
					firstName: this.firstname,
					lastName: this.lastname,
					phone: this.phone.split(' ').join(''),
					email: this.email,
					address: this.getHospital.address,
					zip: this.getHospital.zip,
					city: this.getHospital.city,
					state: this.getHospital.state,
					country: this.getHospital.country,
					addressLatitude: this.getHospital.addressLatitude,
					addressLongitude: this.getHospital.addressLongitude,
					username: this.email,
					// password: '123456789',
					roleId: this.role,
					hospitalId: this.getHospital.id,
          userTimezone: this.addressIsValid ? this.timezone : this.timezone.timeZoneId,

        };
				await this.$store.dispatch('users/createUser', body).then(res => {
					if (res.resFlag === true) {
						if (this.role === 3) {
							const createdDoctorUserId = res.data.id; // res.id;

							var specializationIds = [];
							if (this.specialization !== null && this.specialization.length > 0) {
								this.specialization.forEach(id => {
									specializationIds.push(id);
								});
							}

							if (specializationIds.length > 0) {
								this.$store.dispatch('doctormetadata/assignDoctorToSpetialization', {
									doctorUserId: createdDoctorUserId,
									spetializationTypeIdsList: specializationIds,
								}).then(res => {
									// this.$emit('updated', res);
								});
							}
						}
            this.addressIsValid = true;
						this.resetComponent();
						this.getStaff();
						showSuccessAlert(this.$t('successfully-created-member'));
						this.closeDrawer();
					} else if (res.resFlag === false) {
						showErrorAlert(res.msg);
					}
				});
			} else {
				this.$refs.form.validate();
				showErrorAlert(this.$t('failed'));
			}
		},
		onFileChange (event) {
			const file = event[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.imageSrc = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		closeDrawer () {
      this.resetComponent();
			this.fileUpload = null;
			this.imageSrc = null;
			this.$store.commit('SET_DETAILS_DRAWER', false);
		},
	},
	watch: {
		// PHONE FORMAT
    phone (val) {
      if (val !== undefined) {
        if (!isValidPhoneNumber(val)) {
          this.phoneErrors = true;
        } else {
          this.phoneErrors = false;
        }
        this.phone = new AsYouType().input(val);
      }
    },
	},
};
</script>
