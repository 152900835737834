<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title style="border-radius:0;" class="text-left card-header" id="fixed-card-title2">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title>Add Monitoring Plan</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right">
         <v-icon  @click="closeDrawer" color="white">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <div style="margin-top:10px;" class="px-5">
      <v-text-field dense hide-details outlined v-model="tpName" label="Name"></v-text-field>
      <v-text-field dense hide-details outlined v-model="tpDescription" class="my-3" label="Description"></v-text-field>
      <v-checkbox v-model="isThisMonitoringPlanDefault" label="Is this monitoring plan default?"></v-checkbox>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-btn v-on="on" v-bind="attrs" style="width: 100%;" color="transparent">{{$t('add-measurement')}}</v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in filteredDevices"
              :key="index"
              style="cursor: pointer; text-align:left;"
              @click="assigningDevice(item)"
          >
            <v-list-item-title style="font-size:15px;">
              <template v-if="item.deviceType===3">
                <img src="@/assets/icons/blood_pressure_black.svg" alt="Blood Pressure Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
              <template v-if="item.deviceType===6">
                <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
              <template v-if="item.deviceType===7">
                <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
              <template v-if="item.deviceType===2">
                <img src="@/assets/icons/oxymeter_black.svg" alt="Oximeter Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
              <template v-if="item.deviceType===1">
                <img src="@/assets/icons/thermometer_black.svg" alt="Thermometer Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
              <template v-if="item.deviceType===5">
                <img src="@/assets/icons/scale_black.svg" alt="Weight scale Icon" style="width: 16px; height: 16px; margin-right: 10px;">
                {{item.deviceName}}
              </template>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              outlined
              width="100%"
              style="text-transform: capitalize;"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="saveMethod"
              color="#a51717"
              width="100%"
              style="text-transform: capitalize; color:white;"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

import { closeBlack } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
import { showSuccessAlert } from '@/utils/utilities';
// import patientmetadata from '@/data/store/modules/patientmetadata';

export default {
  data () {
    return {
      closeBlack: closeBlack,
      medicalNote: '',
      rerender: 1,
      myData: null,
      clickedSave: false,
      tpName: '',
      tpDescription: '',
      getDevices: [],
      isLoading: false,
      isMonitoringAdded: false,
      isThisMonitoringPlanDefault: false,
      getTreatmentId: null,
    };
  },

  computed: {
    filteredDevices () {
      // Create a Map to store unique devices based on deviceType
      const uniqueDevicesMap = new Map();

      // Iterate through getDevices array and add unique devices to the Map
      this.getDevices.forEach(device => {
        uniqueDevicesMap.set(device.deviceType, device);
      });
      // Convert Map values back to an array to use in v-select
      return Array.from(uniqueDevicesMap.values());
    },
    ...mapState({
      userData: (state) => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
      monitoring: state => state.treatmentplan.treatmentPlan,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
    }),
  },

  async mounted () {
    await this.getPossibleDevices();
  },

  methods: {
    saveMethod () {
      this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans').then(res => {
        this.getTreatmentId = null;
        this.tpName = null;
        this.tpDescription = null;
      showSuccessAlert('Success');
      });
      this.closeDrawer();
    },
    async assigningDevice (val) {
        this.myData = val;
        this.getDevices = this.getDevices.filter(device => device !== val);
        await this.createClientMonitoringPlan();
    },
    closeDrawer () {
      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async getPossibleDevices () {
      await this.$store.dispatch('medicaldevice/getPossibleDevices', this.userData.id).then(res => {
       this.getDevices = res;
      });
    },
    createClientMonitoringPlan () {
      if (!this.isMonitoringAdded) {
        const body = {
          patientId: null,
          tpName: this.tpName,
          tpDescription: this.tpDescription,
          organizationId: this.userData.organizationId,
          officeId: null,
          isThisDefaultTreatmentPlan: this.isThisMonitoringPlanDefault,
          treatmentPlanType: 2,
        };
        this.$store.dispatch('treatmentplan/createClientMonitoringPlan', body).then(res => {
          this.isMonitoringAdded = true;
          this.getTreatmentId = res.id;
          const body = {
            treatmentPlanId: this.getTreatmentId,
            deviceType: this.myData.deviceType,
            measurementPeriod: 'D',
            measurementsCount: 1,
            time1: '8:00',
            time2: null,
            time3: null,
            time4: null,
          };
          this.$store.dispatch('treatmentplan/assignDevice', body);
          res = this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
          showSuccessAlert('Monitoring plan has been added.');
        });
      }
      if (this.isMonitoringAdded) {
        const body = {
          treatmentPlanId: this.getTreatmentId,
          deviceType: this.myData.deviceType,
          measurementPeriod: 'D',
          measurementsCount: 1,
          time1: '8:00',
          time2: null,
          time3: null,
          time4: null,
        };
        this.$store.dispatch('treatmentplan/assignDevice', body);
      }
    },
  },
};
</script>

<style>

.treatmentstext > .v-input__control > .v-input__slot {
  background-color: #f9f8fb;
}

.draweractionbuttons {
  margin-top: auto;
}
</style>
