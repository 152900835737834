<template>
    <v-form v-model="valid">
      <v-card flat class="border-radius-overflow-none">
        <v-card-title class="card-header">
					<span class="text-color-white">{{$t('create-invoice')}}</span>
          <v-spacer/>
          <v-icon size="20" color="white" @click="closeDrawer">mdi-window-close</v-icon>
        </v-card-title>
<!--        <v-card-text class="text-color-white">-->
<!--					<span class="d-flex align-end justify-end px-3">-->
<!--						<span style="color: red">{{ $t("requiredFields") }}-->
<!--						</span>-->
<!--					</span>-->
<!--        </v-card-text>-->
        <v-row class="px-6 mt-2">
<!--          <v-row class="pl-6 pb-3 pt-3">-->
<!--            <span style="font-size: 18px">{{$t('create-invoice')}}</span>-->
<!--          </v-row>-->

          <v-col cols="12">
            <v-row dense cols="12" class="px-1">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-select
                    @change="organizationChanged"
                    v-model="invoice.clientId"
                    :items="medicalInstitutions"
                    item-text="institutionName"
                    item-value="id"
                    :label="$t('medicalInstitution')"
                    prepend-inner-icon="mdi-office-building mr-1"
                    dense
                    outlined
                    hide-details
                    :rules="[(rule) => !!rule || '']"
                    :menu-props="{ offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.invoiceYear"
                    :label="$t('invoice-year')"
                    prepend-inner-icon="mdi-hospital-building mr-1"
                    dense
                    hide-details
                    outlined
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.invoiceMonth"
                    :label="$t('invoice-month')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    hide-details
                    outlined
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.invoiceDate"
                    :label="$t('invoice-date')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    hide-details
                    outlined
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.dueDate"
                    :label="$t('invoice-due-date')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    outlined
                    hide-details
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.discountPercent"
                    :label="$t('invoice-discount-percent')"
                    prepend-inner-icon="mdi-hospital-building mr-1"
                    dense
                    hide-details
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.taxPercent"
                    :label="$t('invoice-tax-percent')"
                    prepend-inner-icon="mdi-hospital-building mr-1"
                    dense
                    hide-details
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="unitPricePerPatient"
                    :label="$t('invoice-unit-price-per-patient')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    outlined
                    hide-details
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.paymentTerms"
                    :label="$t('invoice-payment-terms')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    outlined
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.paymentMethods"
                    :label="$t('invoice-payment-methods')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    hide-details
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-2">
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    v-model="invoice.additionalInfo"
                    :label="$t('invoice-additional-info')"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    outlined
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions class="fixed-card-actions">
          <v-row dense class="pa-0">
            <v-col cols="6">
              <v-btn
                  @click="closeDrawer"
                  elevation="0"
                  outlined
                  width="100%"
                  style="text-transform: capitalize;"
              >
                {{$t('cancel')}}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                  color="#a51717"
                  width="100%"
                  style="text-transform: capitalize; color:white;"
                  @click="generateInvoiceForOrganization"
              >
                {{$t('save')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
</template>

<script>
import { mapState } from 'vuex';

import Invoice from '../../data/models/Invoice';
import { showSuccessAlert } from '@/utils/utilities';

export default {
  props: ['dialog', 'type', 'selectedInvoice'],
  data () {
    var date = new Date();
    return {
      loader: null,
      loading: false,
      showErrors: false,
      title: 'Something went wrong',
      alertType: false,
      valid: false,
      object: {
        id: null,
        invoiceNumber: null,
        invoiceDate: new Date().toISOString().split('T')[0],
        dueDate: new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0],
        clientId: -1,
        invoiceYear: new Date().getFullYear(),
        invoiceMonth: new Date().getMonth() + 1,
        taxPercent: 0,
        discountPercent: 0,
        unitPricePerPatient: 0,
        paymentTerms: null,
        paymentMethods: null,
        additionalInfo: null,
      },
      medicalInstitutions: [],
      validationErrors: [],
      unitPricePerPatient: 0,
    };
  },
  computed: {
    ...mapState({
      medicalInstitutionsFromRepo: (state) => state.medicalInstitutions.medicalInstitutions,
      invoiceReferencesForOrganization: (state) => state.invoices.invoiceReferencesForOrganization,
      generatedPdfFile: (state) => state.invoices.generatedPdfFile,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    invoice () {
      if (this.type === 'edit') {
        return this.selectedInvoice;
      } else {
        return this.object;
      }
    },
    verifiersInvoiceStatus () {
      if (this.verifiersFromRepo !== null) {
        const verifiersInvoiceStatus = this.verifiersFromRepo.filter(v => v.rvDomain === 'INVOICE_STATUS');
        return verifiersInvoiceStatus;
      } else {
        return [];
      }
    },
  },
  watch: {
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];
        // await this.generateInvoiceForOrganization()
        // 	.then(() => {
        // 		this[l] = false;
        // 	})
        // 	.catch(() => {
        // 		this[l] = false;
        // 	});

        this.loader = null;
        if (this.type === 'edit') {
          await this.updateInvoiceForOrganization()
              .then(() => {
                this[l] = false;
              })
              .catch(() => {
                this[l] = false;
              });
        } else if (this.type === 'add') {
          await this.generateInvoiceForOrganization()
              .then(() => {
                this[l] = false;
              })
              .catch(() => {
                this[l] = false;
              });
        }
      }
    },
  },
  async mounted () {
    // check if we already have loaded verifiers dont call again the API
    if (this.verifiersFromRepo === null) {
      await this.getVerifiers();
    }
    await this.getMedicalInstitutions();
    if (this.type === 'edit') {
      this.organizationChanged();
    } else if (this.type === 'add') {
      // this.phoneNumber = '+1'; // Corrected assignment operator here
    }
  },
  methods: {
    closeDrawer () {
      this.valid = false;
      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async getVerifiers () {
      const header = { domainsList: 'INVOICE_STATUS' };
      await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
    },
    async generatePDF () {
      const invoiceData = { invoiceId: this.selectedInvoice?.id || -1 };
      await this.$store.dispatch('invoices/getInvoicePdf', invoiceData)
          .then(res => {
            showSuccessAlert(this.$t('pdf-downloaded'));
            const url = window.URL.createObjectURL(new Blob([this.generatedPdfFile.data]));
            const link = document.createElement('a');
            const pdfFileName = `invoice-${invoiceData.invoiceId}.pdf`;
            link.href = url;
            link.setAttribute('download', pdfFileName);
            document.body.appendChild(link);
            link.click();
          });
      // showErrorAlert(this.$t('failed'));
    },
    organizationChanged () {
      const selectedOrganizationId = this.invoice.clientId;
      this.getInvoiceReferencesForOrganization(selectedOrganizationId);
    },
    async getInvoiceReferencesForOrganization (organizationId) {
      this.loading = true;
      await this.$store
          .dispatch('invoices/getInvoiceReferencesForOrganization', organizationId)
          .then((resp) => {
            this.loading = false;

            if (this.invoiceReferencesForOrganization) {
              this.unitPricePerPatient = this.invoiceReferencesForOrganization.unitPrice;

              this.object.taxPercent = this.invoiceReferencesForOrganization.taxPercent;
              this.object.discountPercent = this.invoiceReferencesForOrganization.discountPercent;
              this.object.unitPricePerPatient = this.invoiceReferencesForOrganization.unitPrice;
              this.object.paymentTerms = this.invoiceReferencesForOrganization.paymentTerms;
              this.object.paymentMethods = this.invoiceReferencesForOrganization.paymentMethods;
              this.object.additionalInfo = this.invoiceReferencesForOrganization.additionalInfo;
            } else {
              this.unitPricePerPatient = 0;

              this.object.taxPercent = 0;
              this.object.discountPercent = 0;
              this.object.unitPricePerPatient = 0;
              this.object.paymentTerms = '';
              this.object.paymentMethods = '';
              this.object.additionalInfo = '';
            }
          });
    },
    checkValid () {
      this.validationErrors = [];
      // if (this.phoneError) {
      // 	this.validationErrors.push('phone');
      // }
      // if (this.emailError.length > 0) {
      // 	this.validationErrors.push('email');
      // }
      // if (this.address_info.address === '') {
      // 	this.validationErrors.push('address');
      // }
      // if (this.validationErrors.length > 0) {
      // 	return false;
      // } else return true;

      return true;
    },
    async generateInvoiceForOrganization () {
      const requestBody = new Invoice();
      requestBody.invoiceDate = this.invoice.invoiceDate;
      requestBody.dueDate = this.invoice.dueDate;
      requestBody.clientId = this.invoice.clientId;
      requestBody.invoiceYear = this.invoice.invoiceYear;
      requestBody.invoiceMonth = this.invoice.invoiceMonth;
      requestBody.taxPercent = this.invoice.taxPercent;
      requestBody.discountPercent = this.invoice.discountPercent;
      requestBody.unitPricePerPatient = this.unitPricePerPatient; // this.invoice.unitPricePerPatient;
      requestBody.paymentTerms = this.invoice.paymentTerms;
      requestBody.paymentMethods = this.invoice.paymentMethods;
      requestBody.additionalInfo = this.invoice.additionalInfo;

      await this.$store
          .dispatch('invoices/generateInvoiceForOrganization', requestBody)
          .then((res) => {
            this.$emit('false', res);
            this.$store.dispatch('invoices/getInvoices').then(() => {});
            this.closeDrawer();
          });
    },
    async updateInvoiceForOrganization () {
      const invoiceId = this.invoice.id;

      const requestBody = new Invoice();
      requestBody.invoiceId = invoiceId;
      requestBody.invoiceDate = this.invoice.invoiceDate;
      requestBody.dueDate = this.invoice.dueDate;
      requestBody.clientId = this.invoice.clientId;
      requestBody.invoiceYear = this.invoice.invoiceYear;
      requestBody.invoiceMonth = this.invoice.invoiceMonth;
      requestBody.taxPercent = this.invoice.taxPercent;
      requestBody.discountPercent = this.invoice.discountPercent;
      requestBody.unitPricePerPatient = this.unitPricePerPatient; // this.invoice.unitPricePerPatient;
      requestBody.paymentTerms = this.invoice.paymentTerms;
      requestBody.paymentMethods = this.invoice.paymentMethods;
      requestBody.additionalInfo = this.invoice.additionalInfo;
      requestBody.status = this.invoice.status;

      await this.$store
          .dispatch('invoices/updateInvoiceForOrganization', requestBody)
          .then((res) => {
            this.$emit('false', res);
          });
    },
    async getMedicalInstitutions () {
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async deleteInvoice () {
      const invoiceId = this.invoice.id;
      await this.$store
          .dispatch('invoices/deleteInvoiceById', invoiceId)
          .then((res) => {
            this.$emit('false', res);
          });
    },
  },
};
</script>
<style scoped>
.v-select__selection {
  width: 100%;
  justify-content: left;
}
</style>
