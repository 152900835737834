module.exports = class Verifier {
	constructor (object) {
		if (object != null) {
			this.id = object.Id;
			this.lowValue = object.lowValue;
			this.shortDescription = object.shortDescription;
			this.longDescription = object.longDescription;
			this.shortDescriptionEsp = object.shortDescriptionEsp;
			this.longDescriptionEsp = object.longDescriptionEsp;
		} else {
			this.id = null;
			this.lowValue = null;
			this.shortDescription = null;
			this.longDescription = null;
			this.shortDescriptionEsp = null;
			this.longDescriptionEsp = null;
		}
	}
};
