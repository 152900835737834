module.exports = class Hospital {
	constructor (object) {
		if (object != null) {
			this.id = object.id;
			this.medicalInstitutionId = object.medicalInstitutionId;
			this.hospitalName = object.hospitalName;
			this.description = object.description;
			this.contactPerson = object.contactPerson;
			this.contactPhone = object.contactPhone;
			this.contactEmail = object.contactEmail;
			this.address = object.address;
			this.zip = object.zip;
			this.city = object.city;
			this.state = object.state;
			this.country = object.country;
			this.addressLatitude = object.addressLatitude;
			this.addressLongitude = object.addressLongitude;
			this.hospitalSpetializationId = object.hospitalSpetializationId;
		} else {
			this.id = null;
			this.medicalInstitutionId = null;
			this.hospitalName = null;
			this.description = null;
			this.contactPerson = null;
			this.contactPhone = null;
			this.contactEmail = null;
			this.address = null;
			this.zip = null;
			this.city = null;
			this.state = null;
			this.country = null;
			this.addressLatitude = null;
			this.addressLongitude = null;
			this.hospitalSpetializationId = null;
		}
	}
};
