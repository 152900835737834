<template>
    <v-navigation-drawer
		v-model="detailsDrawer"
		right
		app
    stateless
    fixed
		temporary
		active-class="background-color: 'red'"
		:width="480"
    style="z-index:2000"
	>
<!--		<profile-settings v-if="getPage === 'settings'"></profile-settings>-->
		<medical-staff-add v-if="getPage === 'medical staff'"></medical-staff-add>
		<patient-add v-if="getPage === 'patients'"></patient-add>
		<medical-team-add v-if="getPage === 'medical team'"></medical-team-add>
		<assessments-add v-if="getPage === 'assessments'"></assessments-add>
		<monitoring-plans-add v-if="getPage==='patient details'"></monitoring-plans-add>
		<notification-drawer v-if="$route.path === '/dashboard'"></notification-drawer>
		<verifier-add v-if="getPage==='verifier'"></verifier-add>
		<medical-institution-add v-if="getPage==='organization'"></medical-institution-add>
		<hospital-add v-if="getPage==='office'"></hospital-add>
		<hospital-admin-add v-if="getPage==='admins'"></hospital-admin-add>
    <billings-authorization-template-add v-if="getPage === 'billing settings'"></billings-authorization-template-add>
    <billings-users-authorization-add v-if="getPage === 'user billing settings'"></billings-users-authorization-add>
     <medical-note-category-add v-if="getPage === 'medical note category'"></medical-note-category-add>
    <add-monitoring-drawer v-if="getPage==='monitoring'" ></add-monitoring-drawer>
    <add-invocies v-if="getPage==='invoices'" ></add-invocies>
    <add-billing-link-drawer v-if="getPage==='billings'"></add-billing-link-drawer>

<!--      <add-note-drawer v-if="$route.path.match(/\/patientdetails\/\d+/) && activePatientDetailsDrawer == 'addNote'" ></add-note-drawer>-->
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MedicalStaffAdd from '@/views/DrawerDialogs/MedicalStaffAdd.vue';
import PatientAdd from '@/views/DrawerDialogs/PatientAdd.vue';
// import ProfileSettings from '@/views/DrawerDialogs/ProfileSettings.vue';
import MedicalTeamAdd from '@/views/DrawerDialogs/MedicalTeamAdd.vue';
import AssessmentsAdd from '@/views/DrawerDialogs/AssessmentsAdd.vue';
import MonitoringPlansAdd from '@/views/DrawerDialogs/MonitoringPlansAdd.vue';
import NotificationDrawer from '@/views/DrawerDialogs/NotificationDrawer.vue';
import VerifierAdd from '@/views/DrawerDialogs/VerifierAdd.vue';
import MedicalInstitutionAdd from '@/views/DrawerDialogs/MedicalInstitutionAdd.vue';
import HospitalAdd from '@/views/DrawerDialogs/HospitalAdd';
import HospitalAdminAdd from '@/views/DrawerDialogs/HospitalAdminAdd';
import BillingsAuthorizationTemplateAdd from '@/views/DrawerDialogs/AddBillingSettingsTemplate.vue';
import BillingsUsersAuthorizationAdd from '@/views/DrawerDialogs/AddUserBillingSettings.vue';
import MedicalNoteCategoryAdd from '@/views/DrawerDialogs/MedicalNoteCategoryAdd';
import AddMonitoringDrawer from '@/views/DrawerDialogs/AddMonitoringDrawer';
import AddInvocies from '@/views/DrawerDialogs/AddInvocies.vue';
import AddBillingLinkDrawer from '@/views/DrawerDialogs/AddBillingLinkDrawer.vue';
// import AddNoteDrawer from '@/views/DrawerDialogs/AddNoteDrawer.vue';

export default {
	components: {
    MedicalNoteCategoryAdd,
    BillingsUsersAuthorizationAdd,
    AddInvocies,
    AddBillingLinkDrawer,
    BillingsAuthorizationTemplateAdd,
    // AddNoteDrawer,
		AssessmentsAdd,
    AddMonitoringDrawer,
    MedicalStaffAdd,
		PatientAdd,
		// ProfileSettings,
		MedicalTeamAdd,
		MonitoringPlansAdd,
		NotificationDrawer,
		VerifierAdd,
		MedicalInstitutionAdd,
		HospitalAdd,
		HospitalAdminAdd,
	},
	data () {
		return {
      // activePatientDetailsDrawer: '',
			items: [
				{ title: 'Home', icon: 'mdi-view-dashboard' },
				{ title: 'About', icon: 'mdi-forum' },
			],
		};
	},
	computed: {
		...mapState(['barColor', 'barImage', 'webrtcErrors', 'pageSize']),
		...mapGetters({
			pageWidth: 'pageHeight',
			getPage: 'getPage',
		}),
		detailsDrawer: {
			get () {
        // this.selectPatientDrawer(this.$store?.state.activePatientsDrawer);
				return this.$store.state.detailsDrawer;
			},
			set (val) {
				this.$store.commit('SET_DETAILS_DRAWER', val);
			},
		},
	},
	watch: {
		getPage (val) {
		},
	},
  // methods: {
  //   selectPatientDrawer (dat) {
  //     this.activePatientDetailsDrawer = dat;
  //   },
  // },
};
</script>
