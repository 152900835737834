<template>
    <v-card class="mx-auto overflow-y-auto border-radius0"
            elevation="0">
      <v-card-title class="card-header text-left fixed-card-title2">
        <v-row>
          <v-col cols="10">
            <v-toolbar-title>{{$t('manage-appointments')}}</v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-3 px-3">
        <v-tabs
            v-model="tab"
            background-color="#E8E8E8"
            grow
            hide-slider
            height="40px"
            active-class="background-color: primary2 white--text"
        >
          <v-tab
              v-for="(item, i) in tabs"
              :style="
              i === 0
                ? 'height: 40px; text-transform: capitalize; letter-spacing: 0.0; '
                : i === 1
                ? 'height: 40px; text-transform: capitalize; letter-spacing: 0.0;'
                : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px'
            "
              :key="i"
          >
            <span class="font-size15">{{ item.name }}</span>
          </v-tab>
        </v-tabs>
      </v-card-text>
      <v-form v-if="tab === 1" v-model="valid" >
        <v-textarea
            v-model="rejectMessage"
            :label="$t('Reject-Message')"
            outlined
            no-resize
            dense
            class="mx-3"
            :rules="rules"
        ></v-textarea>
      </v-form>
      <v-form v-if="tab === 0" v-model="valid">
        <div class="px-3">
          <v-text-field
              class="mb-n2"
              v-model="appointmentTitle"
              :label="$t('appointment-title')"
              outlined
              :rules="rules"
              dense
          ></v-text-field>

          <div >
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                title=""
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="appointmentDate"
                    :label="$t('appointment-date')"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense

                ></v-text-field>
              </template>
              <v-date-picker v-model="appointmentDate" no-title scrollable :min="getMinDate()" @change="menu2 = false"></v-date-picker>
            </v-menu>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="appointmentTime" transition="scale-transition" offset-y max-width="290px" min-width="290px" :title="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="appointmentTime" class="mt-n3"   :label="$t('appointment-time')"
                              prepend-inner-icon="mdi-clock-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                >
                </v-text-field>
              </template>
              <v-time-picker v-if="menu1" v-model="appointmentTime" :min="getMinTime()" full-width @click:minute="$refs.menu1.save(appointmentTime)" no-title format="ampm"></v-time-picker>
            </v-menu>
            <v-text-field
                class="mt-n3"
                v-model="appointmentPlannedDurationInMin"
                :label="$t('duration')"
                outlined
                :rules="rulesForMinutes"
                dense
                hint="Minutes!"
                type="number"
            ></v-text-field>
          </div>
          <v-textarea
              class="mt-n3"
              v-model="appointmentDescription"
              :label="$t('Appointment-Description')"
              outlined
              :rules="rules"
              dense
              no-resize

          ></v-textarea>
        </div>

      </v-form>
      <v-card-actions class="v-card-action fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                class="cancel-button-style capitalize-text"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                class="save-button-style"
            @click="this.handleButtonClick"
            :disabled="loading || !valid"
            :loading="loading"
            >
              {{buttonText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
</template>
<script>

import { mapState } from 'vuex';
import { closeWhite } from '@/assets';

export default {
	data () {
		return {
			closeWhite: closeWhite,
			loader: null,
			loading: false,
			loadingReject: false,
			loaderReject: false,
			tab: 0,
			tabs: [{ name: this.$t('approve') }, { name: this.$t('reject') }],
			header: 'accept',
			appointmentDate: null,
			appointmentTime: null,
			appointmentPlannedDurationInMin: 15,
			appointmentTitle: '',
			appointmentDescription: '',
			rejectMessage: '',
			valid: false,
			menu1: false,
			menu2: false,
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			dateFormatted: null,
			time1: null,
			item: null,
			filterCase: 'TODAY',
		};
	},
	computed: {
		...mapState({
			notifications: (state) => state.notifications.allNotifications,
		}),
		computedDateFormattedMomentjs () {
			return this.date;
		},
		buttonText () {
			return this.tab === 0 ? this.$t('save') : this.$t('reject');
		},
		notificationsTable () {
			return this.notificationsTabs(this.tab);
		},

		rules () {
			return [(v) => !!v || 'This field cannot be blank!'];
		},
		rulesForMinutes () {
			if (
				(this.appointmentPlannedDurationInMin < 0 ||
              this.appointmentPlannedDurationInMin > 59) || this.appointmentPlannedDurationInMin === ''
			) {
				return ['Please choose a number between 0-59'];
			} else return [];
		},
		dateTime () {
			return this.appointmentDate + 'T' + this.appointmentTime + ':00';
		},
	},

	watch: {
		'$store.state.notifications.notifyProps': {
			handler (newValue) {
				this.item = newValue;
			},
			immediate: true,
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.approveRequest()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
			await this.closeDrawer();
			await this.getMyNotifications();
		},
		async loaderReject () {
			if (this.loaderReject !== null) {
				const l = this.loaderReject;
				this[l] = !this[l];

				await this.rejectRequest()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loaderReject = null;
			}

			await this.closeDrawer();
			await this.getMyNotifications();
		},
	},

	methods: {
		async handleButtonClick () {
			if (this.tab === 0) {
				await this.approveRequest();
				await this.closeDrawer();
				await this.getMyNotifications();
			} else if (this.tab === 1) {
			// 	DISPLAY REJECT FUNCTION
				await this.rejectRequest();
				await this.closeDrawer();
				await this.getMyNotifications();
			}
		},
		async getMyNotifications () {
			this.loading = true;
			await this.$store.dispatch(
				'notifications/ALL_NOTIFICATIONS',
				this.filterCase,
			);
			this.loading = false;
		},
		closeDrawer () {
			this.$store.commit('SET_DETAILS_DRAWER', false);
		},
		async createAppointment () {
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				appointmentDateTime: this.dateTime,
				appointmentPlannedDurationInMin: parseInt(
					this.appointmentPlannedDurationInMin,
				),
				appointmentTitle: this.appointmentTitle,
				appointmentDescription: this.appointmentDescription,
			};
			await this.$store
				.dispatch('appointments/createAppointment', body)
				.then(async () => {
					await this.markAsRead();
				})
				.catch(() => {});
			this.$emit('false');
			this.loading = false;
		},
		async rejectRequest () {
			this.loading = true;
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				approveThis: false,
				responseComment: this.rejectMessage,
			};
			await this.$store
				.dispatch('appointmentrequests/approveRequest', body)
				.then(async () => {
					await this.markAsRead();
				})
				.catch(() => {});
			this.$emit('false');
			this.loading = false;
		},
		async approveRequest () {
			this.loading = true;
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				approveThis: true,
				responseComment: '',
			};
			await this.$store
				.dispatch('appointmentrequests/approveRequest', body)
				.then(async () => {
					await this.createAppointment();
				})
				.catch(() => {});
		},
		async markAsRead () {
			await this.$store.dispatch(
				'notifications/markAsRead',
				this.item.NotificationId,
			);
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
	},
};
</script>
