<template>
    <div>
      <v-card-title class="card-header fixed-card-title2">
        {{$t('addMedicalTeam')}}
        <v-spacer />
        <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text color="primary" class="mt-3" >
        <v-form>
          <v-row dense>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="searchPatient"
                  append-icon="mdi-magnify"
                  :label="$t('search-Patient')"
                  single-line
                  hide-details
                  class="my-1"
              ></v-text-field>
              <v-data-table
                  dense
                  fixed-header
                  :items="filteredPatients"
                  item-key="item.id"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  hide-default-footer
                  :height="testHeight"
                  :search="searchPatient"
                  :headers="patientHeadr"
                  v-model="selectedRows"
                  style="width: 500px;"
                  :items-per-page="-1"
                  class="elevation-1"
                  :footer-props="{
									itemsPerPageText: this.$t('row'),
									itemsPerPageAllText: this.$t('all'),
								}"
              >
                <template v-slot:item="{ item }">
                  <tr  :style="selectedRows.indexOf(item)>-1?'color: white':'' " :class="selectedRows.indexOf(item)>-1?'primary':''" class="pointer" @click="rowClicked(item)">
                    <td style="text-align: left;">{{item.firstName}} {{item.lastName}}</td>
                    <td style="text-align: left;">{{item.email}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-medical-team')"
                  single-line
                  class="my-1"
                  hide-details
              ></v-text-field>

              <v-data-table
                  :key="tableKey"
                  outlined
                  dense
                  v-model="selectedMedicalStaff"
                  :items="staffData"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  fixed-header
                  :headers="staffHeadr"
                  :loading="loading"
                  show-select
                  :items-per-page="-1"
                  :search="search"
              >
                <!-- Customize radio button column -->
                <template v-slot:[`item.radioButton`]="{ item }">
                  <v-radio-group
                      class="pa-0 ma-0"
                      hide-details
                      v-model="primaryDoctorSelected"
                  >
                    <v-radio
                        v-if="item.roleId === 3"
                        :value="item.id"
                        color="primary"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="fixed-card-actions">
        <v-row dense class="pa-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                class="cancel-button-style capitalize-text"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                class="save-button-style mx-1"
                :loading="loading"
                :disabled="loading || checkValidity"
                @click="loader = 'loading'"
            >
              <span>{{ $t("save") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { doctorBlack, nurseBlack } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
  props: ['dialog', 'data', 'deniedPatients'],
  data () {
    return {
      selectedMedicalStaff: [],
      tableKey: 0,
      loader: null,
      loading: true,
      search: '',
      medicalTemaName: null,
      selectedStaff: [],
      relatedPatients: [],
      staff: [],
      doctors: [],
      starterArray: [],
      selectedPatient: undefined,
      medicalTeamTitle: '',
      doctor_black: doctorBlack,
      nurse_black: nurseBlack,
      searchPatient: '',
      searchMedicalTeam: null,
      primaryDoctor: null,
      patients: [],
      primaryDoctorSelected: null,
      staffSelected: [],
      selectedRows: [],
      patientHeadr: [
        {
          text: this.$t('name'),
          align: 'start',
          class: 'table-header pl-3',
          value: 'firstName',
        },
        {
          text: this.$t('email-address'),
          align: 'start',
          class: 'table-header pl-3',
          value: 'email',
        },
      ],
      staffHeadr: [
        {
          text: this.$t('name'),
          align: 'start',
          class: 'table-header pl-3',
          value: 'fullName',
          width: 220,
        },
        {
          text: this.$t('primary-doctor'),
          class: 'table-header px-0',
          value: 'radioButton',
        },
      ],

    };
  },
  computed: {
    ...mapState({
      detailsDrawer: (state) => state.detailsDrawer,
      userDetails: (state) => state.authentication.userData,
      userData: (state) => state.authentication.userData,
      relatedMedicalStaff: (state) => state.hospitals.users,
      // relatedPatientsTemp: (state) => state.hospitals.relatedPatients,
    }),
    ...mapGetters({
      getPatients: 'hospitals/getPatients',
      hospitalId: 'authentication/getHospitalId',
      roleName: 'authentication/getRole',
      getUser: 'authentication/getUser',
    }),
    filteredPatients () {
      return this.getPatients?.filter((patient) => {
        return (
            !patient.hasMedicalTeam &&
            patient.status === 1
        );
      });
    },

    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1284 && this.$vuetify.breakpoint.height <= 803) {
        return '270';
      }
      return '380';
    },
    staffData () {
      return this.staff.filter(s => { return s.id !== this.primaryDoctor && s.status === 1; });
    },
    checkValidity () {
      if (!this.medicalTemaName) {
        return true;
      }
      if (!this.staffSelected || this.staffSelected.length === 0) {
        return true;
      }
      if (!this.primaryDoctorSelected) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedMedicalStaff (val) {
      // console.log('val', val);
    },
    detailsDrawer (val) {
      if (val) {
        this.renderPatients();
        this.staffSelected = [];
      }
    },
    search (val) {
      this.staffSelected = this.staffSelected.filter(id =>
          this.relatedMedicalStaff.some(user => user.id === id),
      );
    },
    primaryDoctorSelected (val) {
      if (!this.staffSelected.includes(val)) {
        this.staffSelected.push(val);
      }
    },
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];
        await this.manageMedicalTeam()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loader = null;
      }
    },
  },
  async mounted () {
    this.staffSelected = [];
    // console.log('staff  : ', this.staff);
  await this.renderPatients();
    // await this.getRelatedMedicalStaff();
    if (this.relatedMedicalStaff == null) {
      await this.getRelatedMedicalStaff(); // we call this only if the data is not loaded, otherwise we use it from the store
    } else {
      if (this.relatedMedicalStaff !== null) {
        this.staff = this.relatedMedicalStaff
            .filter((users) => users.roleId === 3 || users.roleId === 4)
            .map(
                (users) =>
                    (users = {
                      id: users.id,
                      fullName: users.firstName + ' ' + users.lastName,
                      role: users.roleData?.roleNameForDisplay,
                      roleId: users.roleId,
                      status: users.status,
                    }),
            );
        this.doctors = this.staff.filter(s => { return s.roleId === 3; });
      } else this.staff = [];
    }
    this.loading = false;
  },
  methods: {
    async closeDrawer () {
      this.$store.commit('SET_DETAILS_DRAWER', false);
      // await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
      this.staffSelected = [];
      this.primaryDoctorSelected = null;
    },
      async renderPatients () {
        await this.$store.dispatch('hospitals/getRelatedPatients', localStorage.getItem('selectedHospitalAssignedId'));
      },
    rowClicked (row) {
      this.toggleSelection(row);
      this.medicalTemaName = `${row.firstName} ${row.lastName}`;
      if (this.selectedRows.length > 1) {
        this.selectedRows.shift();
      }
    },
    toggleSelection (keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
            selectedKeyID => selectedKeyID !== keyID,
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    // async getPatients () {
    //   this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userDetails.hospitalAssignedData.id);
    //   if (this.relatedPatientsTemp !== null) {
    //     this.relatedPatientsTemp.forEach(item => {
    //       if (item.medicalTeamsData.length === 0) {
    //         item.forAdd = true;
    //         this.patients.push(item);
    //       } else if (item.medicalTeamsData[0].membersList.length === 0) {
    //         item.forAdd = false;
    //         this.patients.push(item);
    //       }
    //     });
    //   }
    // },
    async getRelatedMedicalStaff () {
      try {
        await this.$store.dispatch(
            'hospitals/getRelatedMedicalStaffForHospital',
            this.userDetails.hospitalAssignedData.id,
        );

        // Check if relatedMedicalStaff is not null
        if (this.relatedMedicalStaff) {
          this.staff = this.relatedMedicalStaff
              .filter(user => user.roleId === 3 || user.roleId === 4)
              .map(user => ({
                id: user.id,
                fullName: `${user.firstName} ${user.lastName}`,
                role: user.roleData?.roleNameForDisplay,
                roleId: user.roleId,
                status: user.status,
              }));

          this.doctors = this.staff.filter(s => s.roleId === 3);

          this.relatedMedicalStaff.forEach(item => {
            // Check if medicalTeamsData is not null and handle its length
            if (item.medicalTeamsData && item.medicalTeamsData.length > 0) {
              // If there are medical teams, check membersList
              item.forAdd = item.medicalTeamsData.some(team => team.membersList && team.membersList.length > 0);
              if (item.forAdd) {
                this.patients.push(item);
              }
            } else {
              // If medicalTeamsData is null or empty
              item.forAdd = false;
              this.patients.push(item);
            }
          });
        } else {
          this.staff = [];
        }
      } catch (error) {
        // console.error('Error fetching related users:', error);
        this.staff = [];
      }
    },
    async manageMedicalTeam () {
      // console.log('Items:', this.selectedMedicalStaff); // Log selected staff
      const body = {
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
        patientId: this.selectedRows[0].id,
        primaryDoctorId: this.primaryDoctorSelected,
        medicalTeamTitle: this.medicalTemaName + ' Team',
        medicalTeamDescription: this.medicalTemaName + ' Team',
        status: 1,
      };

      try {
        const res = await this.$store.dispatch('medicalteam/createMedicalTeam', body);

        if (res) {
          showSuccessAlert(this.$t('success'));
          await this.assignMemberToMedicalTeam(res.id);
        } else {
          showErrorAlert(this.$t('failed'));
        }
      } catch (error) {
        showErrorAlert(this.$t('failed'));
      }
      await this.closeDrawer();
    },
    async assignMemberToMedicalTeam (medicalTeamId) {
      const results = [];

      for (const staff of this.selectedMedicalStaff) {
        const bodyAssign = {
          medicalTeamId: medicalTeamId,
          memberUserIdList: [staff.id],
        };

        try {
          const res = await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);
          if (res) {
            results.push(res);
          }
        } catch (error) {
          showErrorAlert(this.$t('failed'));
        }
      }
      try {
        await this.$store.dispatch('hospitals/getRelatedMedicalStaffForHospital', this.userData.hospitalAssignedData.id);
        this.loading = false;
        this.staffSelected = [];

        await this.$store.dispatch('medicalteam/getMedicalTeams', this.getUser.hospitalAssignedData.id);
        this.$emit('false');
      } catch (error) {
        showErrorAlert(this.$t('failed'));
      }
    },
  },
};
</script>
