<template>
  <v-card
      elevation="0"
      v-if="surveyData"
  >
    <v-card-title class="card-header text-left wellness-assessment-add-custom">{{$t('add-assessment')}}
      <v-spacer></v-spacer>
      <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
    </v-card-title>
    <!-- Role -->
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
      >
        <v-text-field
            class="my-3"
            outlined
            dense
            hide-details
            :label="$t('assessment-name')"
            required
            v-model="assessmentName"
            :key="'textField' + componentKey"
        ></v-text-field>
        <!--Type-->
<!--        <v-autocomplete-->
<!--            class="my-3"-->
<!--            :label="$t('type')"-->
<!--            dense-->
<!--            :items="getMentalHealthCategoryForForm"-->
<!--            :item-text="this.$i18n.locale === 'en' ? `shortDescription` : `shortDescriptionEsp`"-->
<!--            hide-details-->
<!--            outlined-->
<!--            item-value="lowValue"-->
<!--            required-->
<!--            v-model="mentalHealthCategory"-->
<!--        ></v-autocomplete>-->
<!--        <i v-if="showCustomAsessment()" class="d-flex ml-1">{{$t('predefined-question-text')}}</i>-->
        <!--Description-->
        <v-textarea
            class="no-resize my-3"
            outlined
            multi-line
            :rows="5"
            dense
            hide-details
            :label="$t('description')"
            required
            no-resize
            v-model="description"
            :key="'textArea' + componentKey"
        ></v-textarea>

        <div v-if="description !==''  ||  assessmentName !=='' ">
                  <v-row  dense class="mx-0 my-2">
                    <span class="black--text font-size15 font-weight-bold ">{{ $t('questions') }}</span>
                  </v-row>
        </div>
        <v-row v-if="description !=='' || assessmentName !==''"   dense class=" my-2 d-flex justify-center align-center">
          <v-btn
              class="white-color capitalize-text"
              elevation="0"
              color="primary2"
              width="98%"
              @click="createQuestion"
          >
            <v-icon dense color="white" class="mdi mdi-plus"></v-icon>
            {{$t('question')}}
          </v-btn>
        </v-row>
        <v-card v-if="showSubmitButton" class="pa-2">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                  v-if="showSubmitButton"
                  class="my-2"
                  outlined
                  v-model="newQuestion"
                  dense
                  hide-details
                  :label="$t('question')"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="showSubmitButton">
            <v-row dense class="mx-0" >
              <v-autocomplete
                  v-model="selectedVerifier"
                  :items="filterSurveyTypes"
                  :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                  item-value="lowValue"
                  hide-details
                  outlined
                  dense
                  :label="$t('type')"
              ></v-autocomplete>
            </v-row>
            <v-text-field
                class="my-3"
                outlined
                v-if="showSubmitButton && selectedVerifier !==3 "
                v-model="newAnswer"
                dense
                hide-details
                :label="$t('answer')"
            ></v-text-field>
            <v-col cols="12" class="text-left d-flex font-weight-bold py-2 px-0 color-black" v-if="showSubmitButton">
              <v-simple-checkbox
                  color="primary"
                  dense
                  v-model="isMandatory"
              >
              </v-simple-checkbox>
              <span>{{$t('is-mandatory')}}</span>
            </v-col>
            <v-btn
                v-if="showSubmitButton"
                color="primary2"
                elevation="0"
                :disabled="newAnswer === '' || newQuestion === '' || selectedVerifier === null "
                width="100%"
                class="mt-2 capitalize-text white-color"
                @click="submitQuestion"
            >
              {{$t('create-question')}}
            </v-btn>
          </div>
        </v-card>

        <div class="margin-top20">
          <v-expansion-panels :value="panel" class="display-block">
            <draggable :list="surveyById.questionsListWithAnswers" @end="(event) => endQuestionsDrag(event, surveyById.questionsListWithAnswers)">
              <v-expansion-panel  v-model="panel" dense v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index"
              >
                <v-expansion-panel-header class="custom-header py-0 my-0" >
                  <v-row dense>
                    <v-col >
                    </v-col>
                    <v-col class="pa-0 d-flex align-center">
                      <v-icon color="main_black" class="mr-2 mb-2" @click.stop="deleteQuestion(question, index)">mdi-trash-can-outline</v-icon>
                    </v-col>
                  </v-row>
                  <v-col >
                    <template v-slot:actions class>
                      <v-icon dense >mdi-chevron-down</v-icon>
                    </template>
                    <v-avatar size="22px" color="chips" class="mr-1">
                      <span class="text-white">{{index+1}}</span>
                    </v-avatar>
                    <span class="font-weight-medium font-size15">{{ question.question }}</span>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-n4">
                  <!--Question-->
                  <v-text-field
                      class="my-3 mt-n1"
                      outlined
                      dense
                      clear-icon="mdi-backspace-outline"
                      clearable
                      @click:clear="deleteOption(question)"
                      hide-details
                      v-model="question.question"
                      :label="$t('question')"
                      required
                  ></v-text-field>
                  <!--                    TRETO-->
                  <v-row dense class="mx-0 pb-3">
                    <v-autocomplete
                        auto-select-first
                        v-model="question.answerTypeId"
                        :items="filterSurveyTypes"
                        :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                        item-value="lowValue"
                        hide-details
                        outlined
                        class="z-index1000"
                        dense
                        @change="updateQuestion(question)"
                        :label="$t('type')"
                    ></v-autocomplete>
                  </v-row>
                  <div v-if="question.answerTypeTitle !== 'Custom text' && question.answerTypeTitle !== null && question.answerTypeTitle !== undefined">
                    <v-row><span class="ma-3 font-weight-bold font-size16" v-if="question.answerTypeId!==3">{{$t('answers')}}</span></v-row>
                    <draggable :list="question.offeredAnswersList" tag="tbody" @end="(event) => endDrag(event, question.offeredAnswersList)">
                      <v-row dense v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                        <div style="width:435px;" v-if="question.answerTypeId!==3 ">
                          <v-text-field
                              class="my-2 ml-2"
                              outlined
                              dense
                              hide-details
                              v-model="answer.possibleAnswer"
                          >
                            <template v-slot:append>
                                <v-row dense class="mt-0">
                                  <v-tooltip top color="primary" style="z-index:9999;">
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox
                                          color="primary"
                                          dense
                                          v-model="answer.triggerNotification"
                                          @click="editAnswer(question, answer, answerIndex)"
                                          v-on="on"
                                      ></v-simple-checkbox>
                                    </template>
                                    <span>Trigger Notification</span>
                                  </v-tooltip>
                                <v-icon

                                    color="main_black"
                                    @click="editAnswer(question, answer, answerIndex)"
                                >
                                  mdi-content-save
                                </v-icon>
                                <v-icon
                                    class="ml-1"
                                    color="main_black"
                                    @click="deleteAnswer(answer, index)"
                                >
                                  mdi-trash-can-outline
                                </v-icon>
                                </v-row>
                            </template>
                          </v-text-field>
                        </div>
                        <div class="ml-auto">
                          <div class="d-flex align-center ml-auto" v-if="surveyById.mentalHealthCategoryId===27">
                            <i class="mr-3">{{$t('score-points')}}</i>
                            <div style="width:50px;">
                              <v-text-field class="mr-2" v-model="answer.scorePoints" :value="answer.scorePoints" hide-details dense @blur="editAnswerRow(answer)" outlined></v-text-field>
                            </div>
                          </div>
                        </div>
                      </v-row>
                    </draggable>
                  </div>
                  <v-text-field
                      class="my-3"
                      outlined
                      v-if="!showFirstButton"
                      v-model="newAnswer"
                      dense
                      hide-details
                      :label="$t('answer')"
                  ></v-text-field>
                  <v-row dense class="mx-n1 mt-3" >
                    <v-col v-if="!showFirstButton">
                      <v-btn
                          class="capitalize-text text-color-white"
                          elevation="0"
                          color="primary2"
                          width="100%"
                          @click="createAnswer(index, question)"
                      >
                        <v-icon color="white">mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{$t('add-answer')}}</span>
                      </v-btn>
                    </v-col>
                    <v-col v-if="showFirstButton && question.answerTypeId!==3">
                      <v-btn
                          elevation="0"
                          color="primary2"
                          width="100%"
                          class="capitalize-text text-color-white"
                          @click="addAnswer()"
                      >
                        <v-icon color="white">mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{ $t('add-new-answer') }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </draggable>
          </v-expansion-panels>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              width="100%"
              :disabled="!this.createdSurvey"
              class="save-button-style"
              @click="updateSurveyTemplate"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import { showSuccessAlert } from '@/utils/utilities';
export default {
  components: {
    draggable,
  },
	name: 'DashboardCoreAppBar',
	data: () => ({
    surveyCreated: false,
    createdSurvey: false,
		showFirstButton: true,
    panel: 0,
		isMandatory: false,
		checkbox: false,
		showSubmitButton: false,
		newQuestion: '',
		showAddButton: false,
    enableAddQuestions: false,
		valid: true,
    componentKey: 0,
    itemForEdit: {},
		surveyId: null,
		newAnswer: '',
		notifyAnswer: false,
		category: [],
		assessmentName: '',
		type: '',
		description: '',
		questionsList: [],
		questionOptions: [],
		selectedVerifier: null,
		mentalHealthCategory: null,
		questions: [
			{
				name: '',
				type: 3,
				options: [],
			},
		],
	}),
	computed: {
		...mapState({
			surveyById: (state) => state.mentalhealth.survey,
			userData: (state) => state.authentication.userData,

		}),
		...mapGetters({
			// hospitalId: 'authentication/getHospitalId',
			getMentalHealthCategoryForForm: 'verifiers/getMentalHealthCategoryForForm',
			getSurveyTypesForForm: 'verifiers/getSurveyTypesForForm',
		}),
		hospitalId () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			return hospitalId;
		},
    filterSurveyTypes () {
      const res = this.getSurveyTypesForForm.filter(item => item.lowValue !== 3);
      return res;
    },
	},
  beforeDestroy () {
    this.assessmentName = '';
  },
  methods: {
    editAnswer (question, answer) {
      const index = question.offeredAnswersList.indexOf(answer);
      if (index === -1) {
        return;
      }
      const updatedAnswer = {
        stQuestionId: question.id,
        possibleAnswer: answer.possibleAnswer,
        orderNumber: index + 1,
        triggerNotification: answer.triggerNotification, // Use the triggerNotification property of the answer
        id: answer.id,
      };
      this.$store.dispatch('mentalhealth/updateAnswer', updatedAnswer).then(() => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyById.id);
        showSuccessAlert(this.$t('answer-updated'));
      });
    },
    resetComponent () {
      this.assessmentName = '';
      this.description = '';
      this.mentalHealthCategory = null;
      this.newQuestion = '';
      this.newAnswer = '';
      this.selectedVerifier = null;
      this.isMandatory = false;
      this.componentKey += 1;
    },
    surveyData () {
      return this.surveyById;
    },
    endDrag (event, items) {
      const itemId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;
      items.forEach((item, index) => {
        item.orderNumber = index + 1;
      });

      const updatedAnswer = {
        stOfferedAnswerId: itemId,
        changedOrderNumberValue: orderId,
      };
      this.$store.dispatch('mentalhealth/stofferAnswerReorder', updatedAnswer);
    },
    endQuestionsDrag (event, items) {
      const questionId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      const updatedQuestion = {
        StQuestionId: questionId,
        ChangedOrderNumberValue: orderId,
      };
      this.$store.dispatch('mentalhealth/stquestionReorder', updatedQuestion);
    },
		addAnswer () {
			this.showFirstButton = false;
			this.showAddButton = true;
		},
    createQuestion () {
      if (!this.surveyCreated) {
        this.createSurvey();
        this.surveyCreated = true;
      }
      this.showSubmitButton = true;
      this.panel = this.questions.length - 1;
    },
		createAnswer (index, question) {
			var createAnswer = {
				stQuestionId: question.id,
				possibleAnswer: this.newAnswer,
				orderNumber: question.offeredAnswersList.length + 1,
				triggerNotification: this.notifyAnswer,
			};
			this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then((res) => {
				if (res.data.triggerNotification) {
					this.selected.push(
						res.data.id,
					);
				}
				this.showFirstButton = true;
				this.newAnswer = '';
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
			});
		},
		updateQuestion (question) {
			const object = {
				question: question.question,
				answerTypeId: question.answerTypeId,
				orderNumber: 1,
				isMandatory: this.isMandatory,
				id: question.id,
			};
			this.$store.dispatch('mentalhealth/updateQuestion', object);
		},
    async submitQuestion () {
      const questionObject = {
        surveyTemplateId: this.surveyId,
        question: this.newQuestion,
        answerTypeId: this.selectedVerifier,
        orderNumber: this.questions.length,
        isMandatory: this.isMandatory,
      };
      const questionResponse = await this.$store.dispatch('mentalhealth/createQuestion', questionObject);
      this.questions.push({
        type: questionResponse.answerTypeId,
        name: questionResponse.question,
        options: [],
        id: questionResponse.id,
      });
      if (this.selectedVerifier === 3) {
        this.newAnswer = '';
      }
      const answerObject = {
        stQuestionId: questionResponse.id,
        possibleAnswer: this.newAnswer,
        orderNumber: 1,
        triggerNotification: this.notifyAnswer,
      };
      const answerResponse = await this.$store.dispatch('mentalhealth/createAnswer', answerObject);
      if (answerResponse.data.triggerNotification) {
        this.selected.push(answerResponse.data.id);
      }
      this.showSubmitButton = false;
      this.newQuestion = '';
      this.newAnswer = '';
      this.selectedVerifier = null;
      this.isMandatory = false;
      this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
    },
		deleteOption (questionOption) {
			this.questionOptions = this.questionOptions.filter(
				(option) => option !== questionOption,
			);
		},
		createSurvey () {
			const object = {
				templateName: this.assessmentName,
				mentalHealthCategoryId: this.mentalHealthCategory,
				templateDescription: this.description,
				hospitalId: this.hospitalId, // this.userData.hospitalAssignedData.id,
			};
        this.$store.dispatch('mentalhealth/createSurvey', object).then(res => {
        this.enableAddQuestions = true;
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
				this.surveyId = res.id;
			});
			this.surveyCreate = true;
      this.createdSurvey = true;
		},
    async updateSurveyTemplate () {
      const object = {
        id: this.surveyId,
        templateName: this.assessmentName,
        mentalHealthCategoryId: 0,
        templateDescription: this.description,
        status: 1,
      };

      await this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
        // Handle success or any other actions you need to take
        this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
        this.surveyId = res.id;
      });
      this.resetComponent();
      this.closeDrawer();
    },
		deleteQuestion (item) {
			this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
				if (this.update) {
					this.$emit('update-survey');
				} else {
					this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
				}
        showSuccessAlert(this.$t('question-deleted'));
			});
		},
		deleteAnswer (item, index) {
			this.questions[index].options = this.questions[index].options.filter(element => element.possibleAnswer !== item.possibleAnswer);
			this.$store.dispatch('mentalhealth/deleteAnswer', item.id).then(res => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
        showSuccessAlert(this.$t('answer-deleted'));
      });
		},
		closeDrawer () {
      // clean up the current select survey template from the store
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);

       this.surveyCreated = false;

			this.valid = false;
      this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
    },
	},

};
</script>
