<template>
  <div>
    <v-card-title class="card-header fixed-card-title2">
      {{$t('addBillingsTemplateforUser')}}
      <v-spacer />
      <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
    </v-card-title>
    <v-card-text color="primary" class="mt-3" >
      <v-form>
        <v-row dense>
          <v-col cols="12" lg="12" md="12" sm="12">
<!--            <v-select-->
<!--                outlined-->
<!--                dense-->
<!--                :items="allMedicalInstitutions"-->
<!--                item-text="institutionName"-->
<!--                item-value="id"-->
<!--                v-model="selectedOrganization"-->
<!--                :label="$t('Find Organization')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                class="my-1"-->
<!--            ></v-select>-->
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-select
                outlined
                dense
                :items="hospitals"
                item-text="hospitalName"
                item-value="id"
                v-model="selectedHospital"
                :label="$t('select-office')"
                single-line
                hide-details
                @change="getStaffByHospitalId(selectedHospital)"
            ></v-select>
          </v-col>
          <v-col class="my-n5" cols="12" lg="12" md="12" sm="12">
<!--            <v-select-->
<!--                outlined-->
<!--                dense-->
<!--                :items="getBillingsTemplatesByOffice"-->
<!--                item-text="id"-->
<!--                item-value="id"-->
<!--                v-model="selectedTemplate"-->
<!--                :label="$t('Find Template')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                @input="getbillingauthorizationtemplatesbyid"-->
<!--                class="my-1"-->
<!--            ></v-select>-->
<!--            <v-select-->
<!--                :loading="loading"-->
<!--                outlined-->
<!--                dense-->
<!--                multiple-->
<!--                @change="getSelectedStaff"-->
<!--                :items="filteredUsers"-->
<!--                :item-text="item => `${item.firstName} ${item.lastName}`"-->
<!--                item-value="id"-->
<!--                v-model="selectedUsers"-->
<!--                :label="$t('Select user')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                class="mt-4"-->
<!--            ></v-select>-->
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-data-table
                :loading="loading"
                outlined
                dense
                :items="filteredUsers"
                :item-value="item => item.id"
                :items-per-page="-1"
                fixed-header
                class="mt-4 elevation-2"
                hide-default-footer
                :height="pageHeight(-290)"
                :headers="userHeaders"
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-left px-1">
                    <v-checkbox
                        v-model="selectedUsers"
                        :value="item.id"
                        hide-details
                        dense
                        class="mt-n1"
                        @change="updateSelectedUsers"
                    ></v-checkbox>
                  </td>
                  <td class="text-left px-0">{{ item.firstName }}</td>
                  <td class="text-left px-0">{{ item.lastName }}</td>
                  <td class="text-left px-0">{{ getRoleName(item.roleId) }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-data-table
                dense
                fixed-header
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :items="billingCodes"
                hide-default-footer
                item-key="id"
                :search="searchCodes"
                :height="400"
                :headers="billingCodesHeaders"
                :items-per-page="-1"
                class="elevation-2"
                :footer-props="{
                itemsPerPageText: this.$t('row'),
                itemsPerPageAllText: this.$t('all'),
    }"
            >
              <template v-slot:item="{ item }">
                <tr >
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td class="px-0">
                    <v-checkbox class="float-end mt-n1" dense hide-details v-model="selectedItems[item.name]" />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              class="save-button-style mx-1"
              :loading="loading"
              @click="loader = 'loading'"
          >
            <span>{{ $t("save") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
  props: ['dialog', 'data', 'deniedPatients'],
  data () {
    return {
      selectedUser: null,
      filteredTemplates: [],
      getBillingsTemplatesByOffice: [],
      filteredUsers: [],
      selectedUsers: [],
      noData: true,
      openDataTable: false,
      selectedTemplate: null,
      templateSelected: false,
      billingCodesNew: ['Template1', 'Template2', 'Template3'],
      billingCodes: [
        { name: 'rpm99453', value: true },
        { name: 'rpm99454', value: true },
        { name: 'rpm99457', value: true },
        { name: 'rpm99458First', value: true },
        { name: 'rpm99458Second', value: true },
        { name: 'rtm96127', value: true },
        { name: 'rtm98975', value: true },
        { name: 'rtm98977', value: true },
        { name: 'rtmG3002', value: true },
        { name: 'rtm98980', value: true },
        { name: 'rtm98981', value: true },
      ],
      selectedItems: {},
      selectedRoleId: null,
      selectedHospital: null,
      selectedOrganization: this.OrganizationId,
      loader: null,
      loading: false,
      searchCodes: '',
      staffHeadr: [
        {
          text: this.$t('codes'),
          align: 'start',
          value: 'fullName',
        },
        {
          text: '',
          align: 'end',
          value: '',
        },
      ],

    };
  },
  computed: {
    ...mapState({
      billingTemplatesAll: (state) => state.usersbillingsauthorization.billingTemplatesAll,
      userDetails: (state) => state.authentication.userData,
      billingsTemplates: (state) => state.usersbillingsauthorization.billingsTemplates,
      allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      getRelatedStaff: (state) => state.hospitals.medicalStaff,
      getBillingsTemplateById: (state) => state.usersbillingsauthorization.getBillingsTemplateById,
    }),
    ...mapGetters({
      hospitalId: 'authentication/getHospitalId',
      OrganizationId: 'authentication/getOrganizationId',
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
    }),
    disable: {
      get () {
        return !this.selectedHospital || this.selectedUsers.length === 0;
      },
      set () {
      },
    },
    hospitals () {
      return this.$store.state.hospitals.hospitals;
    },
    userHeaders () {
      return [
        { text: this.$t(''), class: 'table-header', align: 'start', width: 10 },
        { text: this.$t('name'), class: 'table-header', align: 'start' },
        { text: this.$t('surname'), class: 'table-header', align: 'start ' },
        { text: this.$t('role'), class: 'table-header', align: 'start' },
      ];
    },

    billingCodesHeaders () {
      return [
        { text: this.$t('codes'), class: 'table-header', align: 'start pl-4', width: 10 },
        { text: this.$t(''), class: 'table-header', align: 'end' },
      ];
    },

    // testHeight () {
    //   if (this.$vuetify.breakpoint.width <= 1284 && this.$vuetify.breakpoint.height <= 803) {
    //     return '270';
    //   }
    //   return '750';
    // },
  },
  watch: {
    getBillingsTemplateById: {
      handler (newVal) {
        this.billingCodes.forEach(code => {
          this.$set(this.selectedItems, code.name, false);
        });
        this.updateSelectedItems(newVal);
        this.templateId = newVal.id;
      },
      deep: true,
    },
    primaryDoctorSelected (val) {
      if (!this.staffSelected.includes(val)) {
        this.staffSelected.push(val);
      }
    },
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];
        await this.addTemplate()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loader = null;
      }
    },
  },
  async mounted () {
    this.selectedItems = {};
    this.billingCodes.forEach(code => {
      this.$set(this.selectedItems, code.name, false);
    });
    await this.getHospitals();
    await this.billinguserauthorization();
  },
  methods: {
    updateSelectedUsers (value) {
      this.selectedUsers = value;

      if (this.selectedUsers.length) {
        const selectedStaff = this.getRelatedStaff.find(staff => staff.id === this.selectedUsers[0]);
        this.selectedRoleId = selectedStaff ? selectedStaff.roleId : null;
      } else {
        this.selectedRoleId = null;
      }
    },
    getRoleName (roleId) {
      switch (roleId) {
        case 4 :
          return this.$t('nurse');
        case 7 :
          return this.$t('caregiver');
        case 3:
          return this.$t('doctor');
        case 6 :
          return this.$t('hospital-admin');
      }
    },
    getStaffByHospitalId (hospitalId) {
      this.getStaff(hospitalId);
      this.getTemplate(hospitalId);
      this.getbillingauthorizationtemplatesbyid();
    },

    getbillingauthorizationtemplatesbyid () {
      this.disable = false;
      if (this.getBillingsTemplatesByOffice.length !== 0) {
        this.noData = false;
        this.$store.dispatch('usersbillingsauthorization/getbillingauthorizationtemplatesbyid', this.getBillingsTemplatesByOffice[0].id).then(res => {
          this.templateSelected = true;
        });
      } else {
        this.noData = true;
      }
    },
    getTemplate (officeId) {
      this.getBillingsTemplatesByOffice = this.billingsTemplates.filter(template => template.officeId === officeId);
    },
    async getStaff (hospitalId) {
      this.loading = true;
      await this.$store.dispatch('hospitals/getRelatedMedicalStaff', hospitalId);
      this.filteredUsers = this.getRelatedStaff.filter(user => {
        return !this.billingTemplatesAll.some(template => template.userId === user.id);
      });
      this.loading = false;
    },
    getSelectedStaff (selectedUserIds) {
      if (selectedUserIds.length) {
        const selectedStaff = this.getRelatedStaff.find(staff => staff.id === selectedUserIds[0]);
        this.selectedRoleId = selectedStaff ? selectedStaff.roleId : null;
      }
    },
    async addTemplate () {
      if (!Array.isArray(this.selectedUsers) || this.selectedUsers.length === 0) {
        return;
      }
      let showMessage = true;
      const apiRequests = [];
      for (const userId of this.selectedUsers) {
        const selectedStaff = this.getRelatedStaff.find(staff => staff.id === userId);
        if (selectedStaff) {
          const roleId = selectedStaff.roleId;
          const requestBody = {
            organizationId: this.userDetails.organizationId,
            officeId: this.selectedHospital,
            userId: userId,
            roleId: roleId,
            rpm99453Allowed: this.selectedItems.rpm99453,
            rpm99454Allowed: this.selectedItems.rpm99454,
            rpm99457Allowed: this.selectedItems.rpm99457,
            rpm99458FirstAllowed: this.selectedItems.rpm99458First,
            rpm99458SecondAllowed: this.selectedItems.rpm99458Second,
            rtm96127Allowed: this.selectedItems.rtm96127,
            rtm98975Allowed: this.selectedItems.rtm98975,
            rtm98977Allowed: this.selectedItems.rtm98977,
            rtm98980Allowed: this.selectedItems.rtm98980,
            rtm98981Allowed: this.selectedItems.rtm98981,
            rtmG3002Allowed: this.selectedItems.rtmG3002,
          };
          apiRequests.push(this.$store.dispatch('usersbillingsauthorization/addbillinguserauthorization', requestBody));
        }
      }
      await Promise.all(apiRequests)
          .then((responses) => {
            if (responses.some(res => res)) {
              if (showMessage) {
                showSuccessAlert(this.$t('success'));
                this
                .showMessage = false;
                this.billingsUserAuthorization();
              }
            } else {
              if (showMessage) {
                showErrorAlert(this.$t('failed'));
                showMessage = false;
                this.billingsUserAuthorization();
              }
            }
          })
          .catch(() => {
            if (showMessage) {
              showErrorAlert(this.$t('failed'));
              showMessage = false;
              this.billingsUserAuthorization();
            }
          });

      await this.closeDrawer();
    },

    updateSelectedItems (data) {
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, data[`${code.name}Allowed`]);
      });
    },
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            this.loading = false;
          });
    },
    async getHospitals () {
      this.loading = true;
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.loading = false;
      });
    },
    async closeDrawer () {
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, false);
      });
      this.selectedHospital = null;
      this.selectedOrganization = null;
      this.selectedUsers = [];
      this.selectedTemplate = false;
      this.$store.commit('SET_DETAILS_DRAWER', false);
      this.filteredUsers = [];
      // this.billingCodes = [];
    },
    async billinguserauthorization () {
      await this.$store.dispatch('usersbillingsauthorization/billinguserauthorization');
    },
    async billingsUserAuthorization () {
      this.$store.dispatch('usersbillingsauthorization/billinguserauthorizationV1');
    },
  },
};
</script>
