<template>
  <v-card
      class="mx-auto"
      elevation="0">
    <v-card-title class="card-header text-left fixed-card-title2">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title >{{$t('add-billing-link')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right mt-n1">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
<!--      <v-text-field-->
<!--          outlined-->
<!--          class="mt-3"-->
<!--          dense-->
<!--          disabled-->
<!--          v-model="loggedUser.organizationAssignedData.institutionName"-->
<!--          hide-details="auto"-->
<!--          :rules="[v => !!v || 'Item is required']"-->
<!--          :label="$t('Organization')"-->
<!--          >-->
<!--      </v-text-field>-->
      <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="90"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="selectedDate"
              label="Select date"
              readonly
              v-bind="attrs"
              single-line
              hide-details
              prepend-inner-icon="mdi-calendar"
              dense
              v-on="on"
              class="my-2"
              outlined
          ></v-text-field>
        </template>
        <v-date-picker  v-model="selectedDate" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
      </v-menu>
      <v-select
          outlined
          dense
          :items="formattedPartners"
          item-text="fullName"
          item-value="email"
          v-model="selectedPartner"
          :label="$t('select-partner')"
          single-line
          hide-details
      ></v-select>
      <v-text-field
          outlined
          readonly
          placeholder="email@mail.com"
          class="mt-3"
          dense
          v-model="selectedPartner"
          hide-details="auto"
          :rules="[v => !!v || 'Item is required']"
          :label="$t('send-to')"
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style">
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="createLink"
              :disabled="!selectedDate || selectedPartner === null"
              class="save-button-style">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import BillingsRepository from '@/data/repositories/v1/BillingsRepository';

export default {
  data () {
    return {
      menu1: false,
      selectedDate: new Date().toISOString().substr(0, 7),
      sendTo: '',
      selectedPartner: null,
      getPartnerObject: null,
      formatted: [],
      partnerId: -1,
      partnerEmail: null,
    };
  },
  computed: {
    formattedPartners () {
      return this.partners.map(partner => ({
        ...partner,
        fullName: `${partner.firstName} ${partner.lastName}`,
      }));
    },
    ...mapState({
      partners: (state) => state.users.partners,
      loggedUser: (state) => state.authentication.userData,
    }),
  },
  watch: {
    selectedPartner (newVal) {
      this.newSelectedPartner = this.formattedPartners.find(partner => partner.email === newVal);
    },
  },
  methods: {
    async createLink () {
      const request = {
        organizationId: this.loggedUser.organizationId,
        billingYear: this.selectedDate.substring(0, 4),
        billingMonth: parseInt(this.selectedDate.substring(5, 7), 10),
        sendTo: this.newSelectedPartner.email,
        partnerUserId: this.newSelectedPartner.id,
      };
      try {
        await BillingsRepository.createBillingLinksForOrganization(request);
        await this.getBillingOrganizationLinks();
        this.sendTo = '';
        // eslint-disable-next-line no-empty
      } catch (err) {
      }

      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    closeDrawer () {
      this.sendTo = '';
      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async getBillingOrganizationLinks () {
      await this.$store.dispatch('billings/getBillingLinksForOrganization');
    },
  },
};

</script>
