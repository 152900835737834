<template>
    <v-card
        class="mx-auto"
        elevation="0">
      <v-card-title class="card-header text-left fixed-card-title2">
        <v-row>
          <v-col cols="10">
            <v-toolbar-title >{{$t('add_verifire')}}</v-toolbar-title>
          </v-col>
          <v-col cols="2" class="text-right mt-n1">
            <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <!-- Data -->
          <v-container>
            <v-text-field
                v-if="selectVerifier"
                v-model="search"
                outlined
                append-icon="mdi-magnify"
                :label="$t('search')"
                hide-details
                dense
                style="margin-bottom:10px;"
            />
            <div v-if="selectVerifier">
              <v-data-table 	v-if="returnUniqueVerifiers"
                             class="pa-1 medical-staff-data-table"
                             fixed-header
                             dense
                             item-key="rvDomain"
                             :headers="headers"
                             :headers-length="headers.length"
                             :no-data-text="$t('no-data-available')"
                             :loading-text="$t('loading')"
                             :items="returnUniqueVerifiers"
                             :height="300"
                             ref="table"
                             :search="search"
                             hide-default-footer
                             :footer-props="footerProps"
                             :items-per-page="-1">
                <template v-slot:item="{ item }">
                  <tr @click="clickedVerifier(item)">
                    <td class="text-left">{{item.rvDomain}}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <!-- Divider -->
            <div class="d-flex align-center">
              <span>Select an already predefined verifier ?</span>
              <v-checkbox class="ml-auto" v-model="selectVerifier"></v-checkbox>
            </div>

            <v-row>
              <v-col
                  v-if="selectVerifier"
                class="d-flex pa-1"
                cols="12"
                sm="12">
<!--                <v-text-field-->
<!--                  v-if="selectVerifier"-->
<!--                  outlined-->
<!--                  hide-details-->
<!--                  dense-->
<!--                  v-model="existingVerifier.rvDomain"-->
<!--                  :rules="[v => !!v || 'Item is required']"-->
<!--                  :label="$t('domainname')"-->
<!--                  required>-->
<!--                </v-text-field>-->
                  <v-text-field
                      outlined
                      v-if="verifierClicked"
                      hide-details
                      disabled
                      dense
                      v-model="existingVerifier.rvDomain"
                      :label="$t('domainname')"
                      required>
                  </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-1">
                  <v-text-field
                      v-if="!selectVerifier"
                      outlined
                      hide-details
                      dense
                      v-model="verifierToAdd.rvDomain"
                      :label="$t('domainname')"
                      required>
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.lowValue"
                    :label="$t('lowvalue')"
                    required>
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.shortDescription"
                    :label="$t('shortdescription')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.longDescription"
                    :label="$t('longdescription')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifierToAdd.shortDescriptionEsp"
                  :label="$t('shortdescriptionesp')"
                  required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.longDescriptionEsp"
                    :label="$t('longdescriptionesp')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                class="capitalize-text cancel-button-style">
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                :disabled="!isFormValid"
                class="save-button-style"
                @click="saveVerifier">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import { closeWhite } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
import Verifier from '../../data/models/Verifier';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	name: 'DashboardCoreAppBar',
	components: {},
	data: () => ({
		closeWhite: closeWhite,
		valid: true,
    search: null,
    existingVerifier: null,
    verifierClicked: false,
		menu: false,
    selectVerifier: false,
		verifierToAdd: new Verifier(),
    footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
	}),
	computed: {
		...mapGetters({
			delay: 'authentication/getDelay',
      pageHeight: 'pageHeight',
		}),
    ...mapState({
      allVerifiers: (state) => state.verifiers.allverifires,
    }),
    isFormValid () {
      const {
        lowValue,
        shortDescription,
        longDescription,
        shortDescriptionEsp,
        longDescriptionEsp,
      } = this.verifierToAdd;

      return (
          (this.selectVerifier ? this.existingVerifier?.rvDomain : this.verifierToAdd.rvDomain) &&
          lowValue &&
          shortDescription &&
          longDescription &&
          shortDescriptionEsp &&
          longDescriptionEsp
      );
    },
    headers () {
      let headers = [];
      headers = [
        { text: this.$t('verifier'), value: 'rvDomain', class: 'color-header' },
        { text: this.$t('verifier'), value: 'rvDomain', class: 'color-header' },
      ];
      headers.pop();
      return headers;
    },
    returnUniqueVerifiers () {
      return this.allVerifiers.filter((item, index, self) =>
          index === self.findIndex((v) => v.rvDomain === item.rvDomain),
      );
    },
	},
  watch: {
    selectVerifier (val) {
      if (val === false) {
        this.existingVerifier = null;
      }
    },
  },
	methods: {
    clickedVerifier (item) {
      this.verifierClicked = true;
      this.existingVerifier = item;
    },
		getAllVerifiers () {
			this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
				this.closeDrawer();
			});
		},
    async saveVerifier () {
      if (this.$refs.form.validate()) {
        const body = {
          rvDomain: this.selectVerifier ? this.existingVerifier.rvDomain : this.verifierToAdd.rvDomain,
          lowValue: this.verifierToAdd.lowValue,
          shortDescription: this.verifierToAdd.shortDescription,
          longDescription: this.verifierToAdd.longDescription,
          shortDescriptionEsp: this.verifierToAdd.shortDescriptionEsp,
          longDescriptionEsp: this.verifierToAdd.longDescriptionEsp,
        };

        try {
          const res = await this.$store.dispatch('verifiers/createVerifire', body);
          if (res.data.resFlag) {
            showSuccessAlert(this.$t('success-operation'));
            this.getAllVerifiers(); // refresh the verifiers list
          } else {
            showErrorAlert(this.$t('failed-operation'));
          }
        } catch (error) {
          if (error.response && error.response.status === 409) {
            showErrorAlert(this.$t('failed-operation')); // Generic error message
          } else {
            showErrorAlert(this.$t('failed-operation')); // Generic error message
          }
        } finally {
          this.search = null;
          this.selectVerifier = false;
          this.verifierClicked = false;
          this.verifierToAdd.lowValue = null;
          this.verifierToAdd.shortDescription = null;
          this.verifierToAdd.longDescription = null;
          this.verifierToAdd.shortDescriptionEsp = null;
          this.verifierToAdd.longDescriptionEsp = null;
        }
      } else {
        this.$refs.form.validate();
      }
    },
		save (date) {
			this.$refs.menu.save(date);
		},
		closeDrawer () {
			this.valid = false;
			this.verifierToAdd = {};
			this.$store.commit('SET_DETAILS_DRAWER', false);
		},
	},
};
</script>
