import store from '../data/store';

export function showSuccessAlert (message) {
	store.commit('alerts/add', { type: 'success', message });
	store.commit('alerts/clear', { type: 'success', message });
}

export function showErrorAlert (message) {
	store.commit('alerts/add', { type: 'error', message });
	store.commit('alerts/clear', { type: 'success', message });
}
