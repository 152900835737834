module.exports = class HospitalAdminEditDto {
	constructor (object) {
		if (object != null) {
			this.id = object.id;
			this.firstName = object.firstName;
			this.lastName = object.lastName;
			this.phone = object.phone;
			this.email = object.email;
			this.address = object.address;
			this.zip = object.zip;
			this.city = object.city;
			this.state = object.state;
			this.country = object.country;
			this.addressLatitude = object.addressLatitude;
			this.addressLongitude = object.addressLongitude;
			this.username = object.username;
			this.status = object.status;
			this.gender = object.gender;
			this.birthdate = object.birthdate;
			this.roleId = object.roleId;
			this.userTimezone = object.userTimezone;
			this.userSettingsJson = object.userSettingsJson;
			this.language = object.language;
			this.hospitalId = object.hospitalId;
			this.organizationId = object.organizationId;
		} else {
			this.id = null;
			this.firstName = null;
			this.lastName = null;
			this.phone = null;
			this.email = null;
			this.address = null;
			this.zip = null;
			this.city = null;
			this.state = null;
			this.country = null;
			this.addressLatitude = null;
			this.addressLongitude = null;
			this.username = null;
			this.status = null;
			this.gender = null;
			this.birthdate = null;
			this.roleId = null;
			this.userTimezone = null;
			this.userSettingsJson = null;
			this.language = null;
			this.hospitalId = null;
			this.organizationId = null;
		}
	}
};
