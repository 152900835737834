var convert = require('convert-units');

export function fromTo (value, from, to) {
	try {
		return convert(value).from(from).to(to);
	} catch {
		return NaN;
	}
}

export function calculateAge (dateString) {
	var today = new Date();
	var birthDate = new Date(dateString.split('T')[0]);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}
