<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header fixed-card-title2">
         {{$t('addNewHospitalTooltip')}}
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          lazy-validation
      >
        <v-container>
          <v-row class="pt-2">
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="hospitalToAdd.hospitalName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('office-name')"
                  :key="'textField' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-select
                  class="width-text-field2"
                  hide-details
                  dense
                  v-model="hospitalToAdd.medicalInstitutionId"
                  :items="allMedicalInstitutions"
                  item-value="id"
                  item-text="institutionName"
                  :label="$t('medicalInstitution')"
                  :key="'select' + componentKey"
                  outlined
                  :menu-props="{ offsetY: true }"
              >

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-textarea
                  outlined
                  hide-details
                  dense
                  no-resize
                  v-model="hospitalToAdd.description"
                  :label="$t('description')"
                  :key="'textArea' + componentKey"
              >

              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <div class="pt-4">
                <h5 class="heading text-left">{{this.$t('address')}}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.address"
                  :label="$t('address')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField1' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.city"
                  :label="$t('city')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField2' + componentKey"
                  required>

              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.state"
                  :label="$t('state')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField3' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.zip"
                  :label="$t('zip')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField4' + componentKey"
                  required>

              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.country"
                  :label="$t('country')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField5' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <div class="pt-4">
                <h5 class="heading text-left">{{this.$t('contact')}}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.contactPerson"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('contact-person')"
                  required
                  :key="'textField7' + componentKey"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.contactPhone"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('contact-phone')"
                  @input="formatPhoneNumber"
                  required
                  :key="'textField8' + componentKey"
              >
              </v-text-field>
              <v-text-field
                  class="ml-2"
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.contactEmail"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('contact-email')"
                  required
                  :key="'textField9' + componentKey"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
<!--      <div class="map-container">-->
<!--        <l-map ref="map" :bounds="autofitBounds" style="height: 100px; width: 100px;" :zoom="zoom">-->
<!--          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>-->
<!--          <l-marker v-if="markerLatLng" :lat-lng="markerLatLng" draggable >-->
<!--            &lt;!&ndash;          <template v-if="customIcon">&ndash;&gt;-->
<!--            &lt;!&ndash;            <l-icon :icon-url="customIcon" :icon-size="[42, 42]"></l-icon>&ndash;&gt;-->
<!--            &lt;!&ndash;          </template>&ndash;&gt;-->
<!--            &lt;!&ndash;          <l-tooltip v-if="branchesForMap" :content="branchesForMap[0].name"/>&ndash;&gt;-->

<!--          </l-marker>-->
<!--          <l-circle v-if="markerLatLng" :lat-lng="markerLatLng" :radius="circleRadius" :color="circleColor" :fill-opacity="circleFillOpacity"></l-circle>-->
<!--        </l-map>-->
<!--      </div>-->
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mx-0 px-0 mr-2">
        <v-col cols="6">
          <v-btn
              elevation="0"
              @click="closeDrawer"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="isSaveButtonDisabled()"
              @click="saveHospital"
              class="save-button-style"
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import Hospital from '../../data/models/Hospital';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { AsYouType } from 'libphonenumber-js';
// eslint-disable-next-line no-unused-vars
// import { LMap, LTileLayer, LMarker, LCircle } from 'vue2-leaflet';

export default {
  components: {
    // LMap,
    // LTileLayer,
    // LMarker,
    // LCircle,
  },
	name: 'HopsitalAddDrawer',
	data: () => ({
    componentKey: 0,
    menu: false,
		hospitalToAdd: new Hospital(),

    // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    // attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    // zoom: 18,
    // center: [40.730610, -73.935242],
    // markerLatLng: null,
    // circleRadius: 805,
    // circleColor: 'blue',
    // circleFillOpacity: 0.2,
    // // customIcon: branchClosed,
    // autofitBoundsData: [
    //   [24.75, -66.4],
    //   [49, -125.75],
    // ],
	}),
	computed: {
    ...mapState({
			allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      hospitals: (state) => state.hospitals.hospitals,
		}),
    // autofitBounds: {
    //   get () {
    //     return this.autofitBoundsData;
    //   },
    //   set (value) {
    //     this.autofitBoundsData = value;
    //   },
    // },
	},
  mounted () {
    // this.$nextTick(() => {
    //   if (this.$refs.map && this.$refs.map.mapObject) {
    //     this.$refs.map.mapObject.invalidateSize(true);
    //     this.$refs.map.mapObject.setView([36.985003, -96.714843], 4);
    //   }
    // });
  },
  methods: {
    isSaveButtonDisabled () {
      return (
          !this.hospitalToAdd.hospitalName ||
          !this.hospitalToAdd.medicalInstitutionId ||
          !this.hospitalToAdd.description ||
          !this.hospitalToAdd.address ||
          !this.hospitalToAdd.city ||
          !this.hospitalToAdd.state ||
          !this.hospitalToAdd.zip ||
          !this.hospitalToAdd.country ||
          !this.hospitalToAdd.contactPerson ||
          !this.hospitalToAdd.contactPhone ||
          !this.hospitalToAdd.contactEmail
      );
    },
    formatPhoneNumber (val) {
      this.hospitalToAdd.contactPhone = new AsYouType().input(val);
    },
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.hospitalToAdd.hospitalName = '';
      this.hospitalToAdd.medicalInstitutionId = '';
      this.hospitalToAdd.description = '';
      this.hospitalToAdd.address = '';
      this.hospitalToAdd.city = '';
      this.hospitalToAdd.state = '';
      this.hospitalToAdd.zip = '';
      this.hospitalToAdd.country = '';
      this.hospitalToAdd.contactPerson = '';
      this.hospitalToAdd.contactEmail = '';
      this.hospitalToAdd.contactPhone = '+1';
      // Change the key to trigger component reset
      this.componentKey += 1;
    },
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.closeDrawer();
			});
		},
		async saveHospital () {
      await this.checkAndFixLocationDetails();
			if (this.$refs.form.validate()) {
				const body = {
					medicalInstitutionId: this.hospitalToAdd.medicalInstitutionId,
					hospitalName: this.hospitalToAdd.hospitalName,
					description: this.hospitalToAdd.description,
          contactPerson: this.hospitalToAdd.contactPerson,
					contactPhone: this.hospitalToAdd.contactPhone,
          contactEmail: this.hospitalToAdd.contactEmail,
					address: this.hospitalToAdd.address,
					zip: this.hospitalToAdd.zip,
          city: this.hospitalToAdd.city,
					state: this.hospitalToAdd.state,
					country: this.hospitalToAdd.country,
					addressLatitude: this.hospitalToAdd.addressLatitude,
					addressLongitude: this.hospitalToAdd.addressLongitude,
          hospitalSpetializationId: this.hospitalToAdd.hospitalSpetializationId,
				};
        await this.$store.dispatch('hospitals/createHospital', body).then((res) => {
					if (res) {
            showSuccessAlert(this.$t('successfully-created-organization'));
            this.getHospitals(); // reload the list of hospitals
					} else {
            showErrorAlert(this.$t('failed-operation'));
          }
				});
			} else {
				this.$refs.form.validate();
			}
		},
		save (date) {
			this.$refs.menu.save(date);
		},
		closeDrawer () {
      this.hospitalToAdd.hospitalName = [];
			this.hospitalToAdd = {};
			this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
		},
    async checkAndFixLocationDetails () {
			try {
				if (this.hospitalToAdd.address !== '' && this.hospitalToAdd.city !== '' && this.hospitalToAdd.zip !== '' && this.hospitalToAdd.state !== '' && this.hospitalToAdd.country !== '') {
					var addressForValidation;
					addressForValidation = this.hospitalToAdd.address + ', ' + this.hospitalToAdd.city + ', ' + this.hospitalToAdd.state + ' ' + this.hospitalToAdd.zip + ', ' + this.hospitalToAdd.country;
					const geoLocation = await this.$store.dispatch(
						'googleAPI/getGeoLocation',
						addressForValidation,
					);
					if (!geoLocation) {
						this.hospitalToAdd.addressLatitude = 0.01;
            this.hospitalToAdd.addressLongitude = 0.01;
						return false;
					}
					if (geoLocation.address === undefined || geoLocation.address == null) {
            this.hospitalToAdd.addressLatitude = 0.01;
            this.hospitalToAdd.addressLongitude = 0.01;
						return false;
					}
					const addressComponents = geoLocation.address;
					this.hospitalToAdd.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
					this.hospitalToAdd.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
					this.hospitalToAdd.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.hospitalToAdd.address : geoLocation.display_name;
					this.hospitalToAdd.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
					this.hospitalToAdd.state = addressComponents.state || 'N/A';
					this.hospitalToAdd.country = addressComponents.country || 'N/A';
					this.hospitalToAdd.zip = addressComponents.postcode || 'N/A';
					return true;
				} else {
					this.hospitalToAdd.addressLatitude = 0.01;
          this.hospitalToAdd.addressLongitude = 0.01;
					return false;
				}
			} catch (error) {
        this.hospitalToAdd.addressLatitude = 0.01;
        this.hospitalToAdd.addressLongitude = 0.01;
				return false;
			}
		},
	},
};
</script>
<style scoped>
.map-container {
  margin-top: 20px; /* Adjust this margin as needed */
}
</style>
