module.exports = class MedicalInstitution {
	constructor (object) {
		if (object != null) {
			this.id = object.id;
			this.institutionName = object.institutionName;
			this.description = object.description;
			this.contactPerson = object.contactPerson;
			this.contactPhone = object.contactPhone;
			this.address = object.address;
            this.zip = object.zip;
			this.city = object.city;
			this.state = object.state;
			this.country = object.country;
			this.addressLatitude = object.addressLatitude;
            this.addressLongitude = object.addressLongitude;
			this.contactEmail = object.contactEmail;
			this.mediaServerData = object.mediaServerData;
		} else {
			this.id = null;
			this.institutionName = null;
			this.description = null;
			this.contactPerson = null;
			this.contactPhone = '+1';
			this.address = null;
            this.zip = null;
			this.city = null;
			this.state = null;
			this.country = null;
			this.addressLatitude = null;
            this.addressLongitude = null;
			this.contactEmail = null;
			this.mediaServerData = null;
		}
	}
};
